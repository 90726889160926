@tailwind base;
@tailwind components;
@tailwind utilities;

.border-gradient-primary {
  border-image-source: linear-gradient(90deg, #ff988b 0%, #ff6a88 50.1%, #ff97aa 100%);
  border-image-slice: 1;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, #ff988b 0%, #ff6a88 50.1%, #ff97aa 100%) !important;
  border-radius: 20px;
}

/* React Select  */
.react-select__control {
  border: none !important;
  border-bottom: 1px solid #333 !important;
  border-radius: 0 !important;
}

.react-select__value-container {
  padding: 2px 0 !important;
}

.react-select__placeholder {
  margin: 0 !important;
  font-size: 13px !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open {
  box-shadow: none !important;
  border: none;
}

.react-select__single-value {
  font-size: 13px !important;
  font-weight: 700 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.image-border {
  border: 4px solid #FF6A88 !important;
  border-radius: 7px !important;
}